import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserRules } from "../../common/api/UserAPI";

// export function updateRulesFunc(state, action) {
//     state[action.payload.module] = action.payload.newRules;
//     return state;
// }

export function updateRulesFunc(state, action) {
    const existingRules = state[action.payload.module] || [];
    const preservedRules = existingRules.filter((rule) => !rule.userId);
    state[action.payload.module] = [...preservedRules, ...action.payload.newRules];
    return state;
}

export function addRuleFunc(state, action) {
    if (state.hasOwnProperty(action.payload.module)) {
        state[action.payload.module].unshift(action.payload.newRule);
    } else {
        state[action.payload.module] = [action.payload.newRule];
    }
    return state;
}

export function removeRuleFunc(state, action) {
    state[action.payload.module].splice(action.payload.id, 1);
    return state;
}

export const getRules = createAsyncThunk("data/getRules", async () => {
    const response = await getUserRules();
    return response;
});
