import apiCall from "./apiCall";
import mongoCall from "./mongoCall";

const defaultRules = {
    agreements: [
        {
            moduleId: "agreements",
            type: "Column",
            color: "black",
            applyColorTo: ["Text"],
            conditions: [
                {
                    field: "StartDateVerified",
                    operator: "eq",
                    value: ["No"],
                },
            ],
            active: true,
            field: "ContractStartDate",
            replace: true,
            replaceCustom: "TBD",
            replaceColumn: null,
            addendumPlacement: "Prefix",
        },
    ],
};

export async function getUserInfo() {
    try {
        const result = await apiCall.get("GetUserInfo");
        if (!result.data) {
            throw Error("There was an issue retrieving the user's info.");
        }

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function getUserSettings() {
    try {
        const result = await mongoCall.get("settings");
        if (!result.data) {
            throw Error("There was an issue retrieving the user's settings.");
        }

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function saveUserSettings(newSettings) {
    try {
        await mongoCall.patch("settings", { values: newSettings });
        return;
    } catch (error) {
        throw error;
    }
}

export async function getUserRules() {
    try {
        const result = await mongoCall.get("rules");

        if (!result.data) {
            throw Error("There was an issue retrieving the user's rules.");
        }

        const mergedRules = { ...result.data };

        for (const key in defaultRules) {
            if (result.data.hasOwnProperty(key)) {
                mergedRules[key] = [...defaultRules[key], ...result.data[key]];
            }
        }

        return mergedRules;
    } catch (error) {
        throw error;
    }
}

export async function getUserQuickActions(module) {
    try {
        if (!module) {
            return [];
        }
        const result = await mongoCall.get("quick-actions", { params: { moduleId: module } });
        if (!result.data) {
            throw Error("There was an issue retrieving the user's quick actions.");
        }

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function saveUserQuickActions(values, module) {
    try {
        if (!module) {
            return;
        }
        const result = await mongoCall.patch("quick-actions", { values: values, moduleId: module });
        if (!result.data) {
            throw Error("There was an issue retrieving the user's quick actions.");
        }

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function subscribeToActivity({ moduleId = "", itemId = "" }) {
    try {
        if (!moduleId || !itemId) {
            return;
        }
        const result = await mongoCall.post("activity-feed/subscribe", { moduleId, itemId });

        if (!result.data) {
            throw Error("There was an issue subscribing to the activity feed.");
        }

        return result.data;
    } catch (error) {
        throw error;
    }
}

export async function unsubscribeToActivity({ id }) {
    try {
        if (!id) {
            return;
        }

        const result = await mongoCall.delete(`activity-feed/subscribe/${id}`);

        if (!result.data) {
            throw Error("There was an issue subscribing to the activity feed.");
        }

        return result.data;
    } catch (error) {
        throw error;
    }
}
